<template>
  <div class="interview">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="650px"
    >
      <div class="examRule_head flex">
        <span>{{ details.status == 1 ? '审核中' : details.status == 2 ? '审核成功' : '审核失败' }}详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="interview-detail">
        <div class="tit">请假信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">开始时间：</span>{{ details.start_time }}
          </div>
          <div class="detail-li">
            <span class="color-9">结束时间：</span>{{ details.end_time }}
          </div>
          <div class="detail-li">
            <span class="color-9">请假原因：</span>{{ details.remark }}
          </div>
        </div>
        <div class="tit">审批人信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">审批人：</span>{{ details.audit_name }}
          </div>
          <div class="detail-li"><span class="color-9">工号：</span>{{ details.audit_job_number }}</div>
          <div class="detail-li"><span class="color-9">职位：</span>{{ getPosition(details.audit_position) }}</div>
        </div>
        <div class="tit">其他信息</div>
        <div class="detail-item">
          <div class="detail-li">
            <span class="color-9">申请时间：</span>{{ details.createtime }}
          </div>
          <div class="detail-li">
            <span class="color-9">通过时间：</span>{{ details.updatetime }}
          </div>
           <div class="detail-li" v-if="details.status == 3">
            <span class="color-9">失败原因：</span>{{ details.fail_desc }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      details: {}, //
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.$axios.askLeaveDetail({id}).then((res) => {
        this.dialogVisible = true;
        this.details = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.interview-detail {
  padding: 10px 30px 20px 30px;
  .tit {
    font-size: 18px;
    margin: 20px 0 10px 0px;
  }
  .detail-item {
    display: flex;
    flex-wrap: wrap;
  }
  .detail-li {
    width: 50%;
    margin: 10px 0px;
    font-size: 16px;
  }
}
.message-pop {
  box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
  border-radius: 4px;
  padding: 20px;
  margin-top: 15px;
  .pop-hd {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 18px;
    }
    .hd-price {
      font-size: 18px;
    }
    .size-20 {
      font-size: 20px;
    }
    .sex {
      font-size: 16px;
      margin: 0 5px;
    }
    .tag {
      line-height: 28px;
      padding: 0 10px;
      border-radius: 3px;
      background: #d5e3ff;
      font-size: 14px;
      color: #3273f6;
      margin: 0 5px;
    }
    .tag.orange {
      background: rgba(255, 117, 42, 0.1);
      color: #ff752a;
    }
  }
}
</style>
