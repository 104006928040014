<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          @click="changeHeadIndex(index)"
          class="li"
          :class="{ active: index == headIndex }"
          v-for="(item, index) in headList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div @click="changeType(0)" class="li nav-link" :class="{active:0 == typeIndex}">我的审核</div>
        <div v-if="userInfo.position != 4 && userInfo.position != 5" @click="changeType(1)" class="li nav-link" :class="{active:1 == typeIndex}">审核他人</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--audit-list start-->
      <div class="audit-list" v-if="headIndex == 0">
        <!--audit-item start-->
        <template v-if="typeIndex == 0">
          <div class="audit-item" v-for="(item, index) in list" :key="index">
            <div class="item-flex">
              <div class="item-td">
                <span class="color-9">申请时间：</span>{{ item.createtime }}
              </div>
              <div class="item-td">
                <span class="color-9">开始时间：</span>{{ item.start_time }}
              </div>
              <div class="item-td">
                <span class="color-9">结束时间：</span>{{ item.end_time }}
              </div>
              <div class="item-td">
                <span class="color-9">审批人：</span>{{ item.audit_name }}
              </div>
              <div class="item-td">
                <span class="color-9">工号：</span>{{ item.audit_job_number }}
              </div>
              <div class="item-td">
                <span class="color-9">职位：</span
                >{{ getPosition(item.audit_position) }}
              </div>
              <div class="item-td">
                <span class="color-9">请假原因：</span>{{ item.remark }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="audit-item" v-for="(item, index) in list" :key="index">
            <div class="item-l">
              <div class="item-flex">
                <div class="item-td">
                  <span class="color-9">工号：</span>{{ item.sub_job_number }}
                </div>
                <div class="item-td">
                  <span class="color-9">申请人：</span>{{ item.sub_name }}
                </div>
                <div class="item-td">
                  <span class="color-9">职位：</span>{{ getPosition(item.sub_position) }}
                </div>
                <div class="item-td">
                  <span class="color-9">申请时间：</span>{{ item.createtime }}
                </div>
                <div class="item-td">
                  <span class="color-9">开始时间：</span>{{ item.start_time }}
                </div>
                <div class="item-td">
                  <span class="color-9">结束时间：</span>{{ item.end_time }}
                </div>
                <div class="item-td">
                  <span class="color-9">请假原因：</span
                  >{{ item.remark }}
                </div>
              </div>
            </div>
            <div class="item-r">
              <el-button class="audit-list-btn blue" @click="changeItem(item.id)">同意</el-button>
              <el-button class="audit-list-btn red" @click="$refs.rpop.open(item.id,3)"
                >拒绝</el-button
              >
            </div>
          </div>
        </template>

        <!--audit-item end-->
      </div>
      <!--audit-list end-->
      <!--record_table start-->
      <div class="record_table" v-else>
        <div
          class="record_tr"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="record_td">
            <div class="td">
              <span class="color-9">申请时间：</span>{{ item.createtime }}
            </div>
            <div class="td"><span class="color-9">审批人：</span>{{ item.audit_name }}</div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color-9">通过时间：</span>{{ item.updatetime }}
            </div>
            <div class="td"><span class="color-9">工号：</span>{{ item.audit_job_number }}</div>
          </div>
          <div class="record_td">
            <div class="td"><span class="color-9">职位：</span>{{ getPosition(item.audit_position) }}</div>
          </div>
          <div class="record_td">
            <div class="td">
              <span class="color_blue" @click="$refs.lpop.open(item.id)">查看详情>></span>
            </div>
          </div>
        </div>
      </div>
      <!--record_table end-->
      <div class="paging" v-if="list.length != 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--ind-main end-->
    <!-- 拒绝弹框 S -->
    <audit-pop-re ref="rpop" @succend="init"></audit-pop-re>
    <!-- 拒绝弹框 E -->
     <!-- 详情弹框 S -->
    <leave-pop ref="lpop"></leave-pop>
    <!-- 详情弹框 E -->
  </div>
</template>

<script>
import auditPopRe from '@/components/AuditPopRe.vue'
import LeavePop from '@/components/LeavePop.vue'
import { mapGetters } from "vuex";
export default {
  components:{auditPopRe,LeavePop},
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      headIndex: 0, //
      typeIndex: 0, //
      headList: ["审核中", "审核成功", "审核失败"],
    };
  },
  methods: {
    changePage (e) {
      this.page =e
      this.getaskLeaveList()
    },
    changeItem (id) {
      this.$axios.agreeAskLeave({id}).then(res=>{
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.init()
      })
    },
    //切换审核身份
    changeType (index) {
      if (this.typeIndex == index) return
      this.typeIndex = index
      this.init()
    },
    changeHeadIndex(index) {
      if (this.headIndex == index) return
      this.headIndex = index;
      this.init();
    },
    init() {
      this.list = []
      this.page = 1;
      this.getaskLeaveList();
    },
    //请假列表
    getaskLeaveList() {
      this.$axios
        .askLeaveList({
          page: this.page,
          status: this.headIndex + 1,
          role_type: this.typeIndex + 1,
        })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
  },
  created() {
    this.getaskLeaveList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.message-select {
  /deep/ .el-select {
    width: 100px;
    min-width: 100px !important;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;
  .head-nav {
    display: flex;
  }
  .li {
    cursor: pointer;
    margin-right: 55px;
    line-height: 36px;
  }
  .nav-link {
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }
  .active {
    color: #3273f6;
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}
.ind-main {
  background: #fff;
  padding: 10px 0;
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
  .color_blue {
    cursor: pointer;
    color: #3273F6;
  }
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
    margin: 5px 0px;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-flex {
      display: flex;
      flex-wrap: wrap;
    }
    .item-td {
      font-size: 16px;
      margin: 5px 0;
      line-height: 1.6;
      min-width: 30%;
    }
    .item-l {
      width: calc(100% - 150px);
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:nth-child(2n) {
    background: #f7fcff;
  }
}
.record_table {
  display: table;
  width: 100%;
  margin: 00px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
  vertical-align: middle;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
.audit-list {
  .audit-list-btn {
    height: 36px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 36px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }
  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }
  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }
  .audit-item {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-flex {
      display: flex;
      flex-wrap: wrap;
    }
    .item-td {
      font-size: 16px;
      margin: 5px 0;
      line-height: 1.6;
      min-width: 30%;
    }
    .item-l {
      width: calc(100% - 150px);
    }
    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }
  .audit-item:nth-child(2n) {
    background: #f7fcff;
  }
}
</style>
